<template>
  <div>
    <div class="tw-flex tw-justify-center tw-uppercase tw-pb-5">
      <v-img
        max-height="40"
        max-width="40"
        src="@/assets/logo.svg"
      />
    </div>
    <Title class="tw-capitalize tw-font-medium tw-text-2xl">{{title}}</Title>
  </div>
</template>

<script>
import Title from '@/components/atoms/Title'

export default {
  components: {
    Title,
  },
  props: ['title'],

}
</script>

<style>

</style>